<template>
  <AgenciesList />
</template>

<script>
  export default {
    components: {
      AgenciesList: () => import('@/components/people/agencies/AgenciesList')
    }
  }
</script>